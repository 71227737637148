$('.anchorNav').on('click', function(e) {

    e.preventDefault();

    var $anchor = $(this);


    $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top
    }, 800, 'easeInOutExpo');

    setTimeout(function(){
        $('.main-nav .btn-toggle-menu').removeClass('active');
        // $('.main-nav .toggle-menu').slideToggle();
    }, 950);
});