$(function() {
    $('.btn-toggle-menu').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.toggle-menu').slideToggle();
    });
});

$(function() {
    $('.head-whats_toggle').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.head-whats_numbers').slideToggle();
    });
});

$(function() {

    $('.btn-filters').click(function(e) {
        
        e.preventDefault();

        $('.page-products_left').addClass('opened');

        $('html, body').animate({
            scrollTop: $('body').offset().top
        }, 600);
        
    });

});

$(function() {
    $('.btn-close').click(function(e) {
        
        e.preventDefault();

        $('.page-products_left').removeClass('opened');
        
    });
});



